// Gatsby supports TypeScript natively!
import React, { useState } from "react"
import { PageProps, Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { rhythm } from "../utils/typography"
import NewsLetter from "../components/NewsLetter"
import Alert from "../components/AlertCom"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const [result, setResult] = useState('');
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges;
  const [hide, setHide] = useState('');

  if (result && Object.keys(result).length > 0) {
    setTimeout(() => {
      setHide('true');
    }, 2000);
  }

  if (hide === "true") {
    setTimeout(() => {
      setResult('');

      setHide("");
    }, 500);
  }
  return (
    <>
      <Alert result={result} hide={hide} />

      <Layout location={location} title={siteTitle}>

        <SEO title="utdev" />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article key={node.fields.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small>{node.frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          )
        })}
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <NewsLetter res={setResult} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </Layout>
    </>

  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
